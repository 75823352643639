import {useContext, useEffect, useState} from "react";
import {createContext} from "react";
import {useQuery, useMutation} from "@apollo/client";
import {CHATBOX_QUERY, CREATE_CHATBOX_MUTATION, MESSAGE_SUBSCRIPTION, MESSAGE_QUERY} from "../../graphql";

const LOCALSTORAGE_KEY = "me";
const savedMe = () => {
    try {
        const item = localStorage.getItem(LOCALSTORAGE_KEY);
        return item ? item : '';
    } catch (error) {
        return '';
    }
}

const useChat = (props) => {

    const [me, setMe] = useState(savedMe || '');
    const [friend, setFriend] = useState('');
    // const {data, loading, error, subscribeToMore} = useQuery(MESSAGE_QUERY);
    // console.log("data", data);
    //
    // useEffect(() => {
    //     if (data) {
    //         console.log("Data", data);
    //         setMessages(data.chatBox.messages);
    //     }
    // }, []);

    const [signedIn, setSignedIn] = useState(false);
    const [displayStatus, setDisplayStatus] = useState({type: '', msg: ''});


    // const [startChat] = useMutation(CREATE_CHATBOX_MUTATION);


    // useEffect(() => {
    //     try {
    //         subscribeToMore({
    //             document: MESSAGE_SUBSCRIPTION,
    //             variables: {from: me, to: friend},
    //             updateQuery: (prev, {subscriptionData}) => {
    //                 if (!subscriptionData.data) return prev;
    //                 const newMessage = subscriptionData.data.message.message;
    //                 console.log("newMessage", newMessage);
    //                 return Object.assign({}, prev, {
    //                     chatBox: {
    //                         messages: [...prev.chatBox.messages, newMessage],
    //                     }
    //                 });
    //             }
    //         });
    //     } catch (error) {
    //         console.log(error);
    //     }
    // }, [subscribeToMore]);

    // useEffect(() => {
    //     if (signedIn) {
    //         localStorage.setItem(LOCALSTORAGE_KEY, me);
    //     }
    // }, [signedIn, me]);


    const [status, setStatus] = useState({})
    const [messages, setMessages] = useState([])

    return {
        signedIn,
        setSignedIn,
        displayStatus,
        setDisplayStatus,
        status,
        setStatus,
        messages,
        setMessages,
        me,
        // startChat,
        setMe
    }
}



export default useChat;

// export default useChat;