import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "antd/dist/antd.css";
// import {ChatProvider} from "./Containers/hooks/useChat";

import {
    ApolloClient, InMemoryCache, ApolloProvider, split, HttpLink
} from '@apollo/client';
import {getMainDefinition} from "@apollo/client/utilities";
import {GraphQLWsLink} from "@apollo/client/link/subscriptions";
import { createClient } from 'graphql-ws';
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const httpLink = new HttpLink({
    uri: 'https://wp1111-hw9.onrender.com/graphql',
});

const wsLink = new GraphQLWsLink(createClient({
    url: 'wss://wp1111-hw9.onrender.com/graphql',
}));

const splitLink = split(
    ({query}) => {
        const definition = getMainDefinition(query);
        return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
        );
    },
    wsLink,
    httpLink,
);

const client = new ApolloClient({
    link: splitLink,
    cache: new InMemoryCache(),
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <ApolloProvider client={client}>
            {/*<ChatProvider>*/}
                <App/>
            {/*</ChatProvider>*/}
        </ApolloProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
