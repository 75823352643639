import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  //margin: 20px;
  //height: 80px;

  h1 {
    margin: 0;
    margin-right: 20px;
    font-size: 3em;
  }
`;

const Title = ({name}) => (
    <Wrapper>
        <h1>{name ? `${name}'s` : "My"} Chat Room</h1>
    </Wrapper>
)


export default Title;