import styled from "styled-components";
import Tag from "antd/es/tag";

const StyledMessage = styled.div`
  //width: 100%;
  text-align: ${({isMe}) => isMe ? 'right' : 'left'};
  //height: 300px;
  //background: #eeeeee52;
  //border-radius: 10px;
  //margin: 20px;
  //padding: 20px;
  //overflow: auto;
  flex-direction: ${({isMe}) => isMe ? 'row-reverse' : 'row'};
`;

const Message = ({isMe, message, name, me}) => {
    return (
        <StyledMessage isMe={isMe} >
            <p style={{ width: "auto" }}><Tag color={'blue'}>{name}</Tag>{message}</p>
        </StyledMessage>
        // <div style={{ flexDirection: `${({isMe}) => isMe ? 'row-reverse' : 'row'}` }}>
        //
        // </div>
    )
}

export default Message;