import {UserOutlined} from '@ant-design/icons';
import {Input} from "antd";

const LogIn = ({me, setName, onLogin}) => {
    return (
        <Input.Search
            size={'large'}
            placeholder="Enter your name"
            enterButton="Sign In"
            value={me}
            onChange={(e) => {
                console.log("e.target.value", e.target.value);
                setName(e.target.value);
            }}
            prefix={<UserOutlined/>}
            onSearch={(name) => onLogin(name)}
            style={{width: 300, margin: 50}}
        />
    )
}

export default LogIn;