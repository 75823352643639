// import './App.css'
import {Button, Input, Tag, message} from 'antd'
import React, {useEffect, useRef, useState} from 'react'
import styled from "styled-components";
import Title from "../Components/Title";
import {Tabs} from "antd";
import ChatModal from "../Components/ChatModal";
import Message from "../Components/Message";
import {useQuery, useMutation, useSubscription} from "@apollo/client";
import {
    CHATBOX_QUERY, CREATE_CHATBOX_MUTATION, CREATE_MESSAGE_MUTATION,
    MESSAGE_SUBSCRIPTION, MESSAGE_QUERY
} from "../graphql";


const ChatBoxesWrapper = styled(Tabs)`
  width: 100%;
  //height: 300px;
  background: #eeeeee52;
  border-radius: 10px;
  margin: 20px;
  padding: 20px;
  overflow: auto;
`;

const FootRef = styled.div`
  //height: 20px;
`;


function ChatRoom({status, messages, sendMessages, me, setMessages}) {
    const [body, setBody] = useState('')
    const bodyRef = useRef(null)
    const msgFooter = useRef(null);
    const [chatBoxes, setChatBoxes] = useState([]);
    const [activeKey, setActiveKey] = useState("0");
    const [modalOpen, setModalOpen] = useState(false);
    const [msgSent, setMsgSent] = useState(false);

    const {data, loading, error, subscribeToMore} = useQuery(MESSAGE_QUERY);
    console.log(messages);

    useEffect(() => {
        try {
            subscribeToMore({
                document: MESSAGE_SUBSCRIPTION,
                variables: {from: me, to: "0"},
                updateQuery: (prev, {subscriptionData}) => {
                    if (!subscriptionData.data) return prev;
                    const newMessage = subscriptionData.data.message.body;
                    return Object.assign({}, prev, {
                            messages: [...prev.messages, newMessage],
                    });
                }
            });
        } catch (error) {
            console.log(error);
        }
    }, [subscribeToMore]);

    // const subscriptionPayload = useSubscription(
    //     MESSAGE_SUBSCRIPTION,
    //     { variables:{from: me, to: "0"} }
    // );

    useEffect(() => {
        if (data) {
            console.log(data)
            setMessages(data.messages);
        }
    }, [data]);

    const scrollToBottom = () => {
        msgFooter.current?.scrollIntoView
        ({behavior: 'smooth', block: "start"});
    };

    useEffect(() => {
        scrollToBottom();
        setMsgSent(false);
    }, [msgSent, chatBoxes, messages]);

    const removeChatBox =
        (targetKey, activeKey) => {
            const index = chatBoxes.findIndex
            (({key}) => key === activeKey);
            const newChatBoxes = chatBoxes
                .filter(({key}) =>
                    key !== targetKey);
            setChatBoxes(newChatBoxes);
            return activeKey ?
                activeKey === targetKey ?
                    index === 0 ?
                        '' : chatBoxes[index - 1].key
                    : activeKey
                : '';
        };

    const displayStatus = (status) => {
        if (status.msg) {
            const {type, msg} = status;
            const content = {
                content: msg,
                duration: 0.5,
            }
            switch (type) {
                case 'success':
                    message.success(content);
                    break;
                case 'error':
                    message.error(content);
                    break;
            }
        }
        setMsgSent(true);
    }

    useEffect(() => {
        displayStatus(status)
    }, [status]);


    const renderChat = (filter_messages) => {
        setMsgSent(true);
        return (
            <div className="App-messages">
                {filter_messages.length === 0 ?
                    (<p style={{color: '#ccc'}}>
                        No messages...
                    </p>) : (
                        filter_messages.map(({sender, to, body}, index) => (
                            <Message key={index} name={sender} message={body} isMe={sender === me} me={me}/>
                        ))
                    )}
                <FootRef ref={msgFooter}></FootRef>
            </div>
        )
    }

    const extractChat = (friend) => {
        const chats = renderChat(messages.filter(({
                                                      sender: name,
                                                      to: to
                                                  }) => ((name === friend) && (to === me)) || ((name === me) && (to === friend))));
        return chats;
    }

    useEffect(() => {
        if (messages.length > 0) {
            const newMsg = messages[messages.length - 1];
            const friend = newMsg.to;
            const chat = extractChat(friend);
            setChatBoxes((prevChatBoxes) => {
                let newChatBoxes = [];
                prevChatBoxes.forEach((chatBox) => {
                    if (chatBox.key === friend) {
                        newChatBoxes.push({label: friend, key: friend, children: chat});
                    } else {
                        newChatBoxes.push(chatBox);
                    }
                });
                return newChatBoxes;
            })
        }
        setMsgSent(true);
    }, [messages]);

    const createChatBox = (friend) => {
        if (chatBoxes.some
        (({key}) => key === friend)) {
            throw new Error(friend +
                "'s chat box has already opened.");
        }
        const chat = extractChat(friend);
        setChatBoxes([...chatBoxes, {label: friend, children: chat, key: friend}]);
        setMsgSent(true);
        return friend;
    }


    const [startChat] = useMutation(CREATE_CHATBOX_MUTATION);
    const [createMessage] = useMutation(CREATE_MESSAGE_MUTATION);
    const handleCreateChatBox = async (name) => {
        await startChat({
            variables: {
                name1: me,
                name2: name
            }
        });
        setModalOpen(false);
        createChatBox(name);
        // console.log(messages);
    }

    const handleCreateMsg = async (message) => {
        await createMessage({
            variables: {
                name: me,
                to: activeKey,
                body: message
            }
        });
        setBody('');
        setChatBoxes((prevChatBoxes) => {
            let newChatBoxes = [];
            prevChatBoxes.forEach((chatBox) => {
                if (chatBox.key === activeKey) {
                    newChatBoxes.push({label: activeKey, key: activeKey, children: renderChat(messages)});
                } else {
                    newChatBoxes.push(chatBox);
                }
            });
            return newChatBoxes;
        })
        bodyRef.current.focus();
    }

    return (
        <>
            <Title name={me}/>
            <div className="App">
                <ChatBoxesWrapper
                    onChange={(key) => {
                        setActiveKey(key);
                        extractChat(key);

                    }}
                    type="editable-card"
                    onEdit={(targetKey, action) => {
                        if (action === 'remove') {
                            setActiveKey(removeChatBox(targetKey, activeKey))
                        } else if (action === 'add') {
                            setModalOpen(true);
                        }
                    }}
                    activeKey={activeKey}
                    items={chatBoxes}
                >

                </ChatBoxesWrapper>
                <Input.Search
                    enterButton="Send"
                    value={body}
                    ref={bodyRef}
                    onChange={(e) => setBody(e.target.value)}
                    placeholder="Type a message here..."
                    onSearch={(message) => {
                        if (!message || !me) {
                            displayStatus({
                                type: 'error',
                                message: 'Please enter a username and a message body.'
                            })
                            return;
                        }
                        handleCreateMsg(message);
                    }}
                ></Input.Search>
            </div>

            <ChatModal
                open={modalOpen}
                onCreate={({name}) => handleCreateChatBox(name)}
                onCancel={() => setModalOpen(false)}
            />
        </>
    )
}

export default ChatRoom
