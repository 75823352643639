import {gql} from '@apollo/client';

export const CHATBOX_QUERY = gql`
    query chatBox($name: String!) {
        chatBox(name: $name) {
            name
            messages {
                sender
                body
            }
        }
    }`;

export const MESSAGE_QUERY = gql`
    query message {
        messages {
            sender
            body
            to
        }
    }`;


// Compare this snippet from hw8\frontend\src\graphql\queries.js:
// import {useQuery, useMutation} from "@apollo/client";
// import {CHATBOX_QUERY, CREATE_CHATBOX_MUTATION, MESSAGE_SUBSCRIPTION} from "../../graphql";
// const LOCALSTORAGE_KEY = "me";
//