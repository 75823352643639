import './App.css'
import {Button, Input, Tag, message} from 'antd'
import React, {useEffect, useRef, useState} from 'react'
import useChat from "./Containers/hooks/useChat";
import ChatRoom from "./Containers/ChatRoom";
import SignIn from "./Containers/SignIn";


function App() {
    const {
        signedIn,
        setSignedIn,
        displayStatus,
        setDisplayStatus,
        status,
        setStatus,
        messages,
        setMessages,
        me,
        setMe
    } = useChat();

    return (
        <>
            {signedIn ? <ChatRoom status={status} messages={messages} sendMessages={setMessages}  me={me} setMessages={setMessages}/> :
                <SignIn me={me} setMe={setMe} setSignedIn={setSignedIn} status={status} setStatus={setStatus}/>}
        </>
    )
}

export default App
