import {Button, Input, Tag, message} from 'antd'
import React, {useEffect, useRef, useState} from 'react'
import LogIn from "../Components/LogIn";
import Title from "../Components/Title";
import styled from "styled-components";

const StyledMessage = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
`;

const getMe = () => {
    return localStorage.getItem('me') || '';
}

const SignIn = ({me, setMe, setSignedIn, status, setStatus}) => {

    const displayStatus = (status) => {
        if (status.msg) {
            const {type, msg} = status;
            const content = {
                content: msg,
                duration: 0.5,
            }
            switch (type) {
                case 'success':
                    message.success(content);
                    break;
                case 'error':
                    message.error(content);
                    break;
            }
        }
    }

    useEffect(() => {
        displayStatus(status)
    }, [status]);

    const handleLogin = (name) => {
        if (!name) {
            setStatus({type: 'error', msg: 'Please enter your name!'});
        } else {
            setSignedIn(true);
            setMe(name);
        }
    }


    return (
        <>
            <Title/>
            <StyledMessage>
                <LogIn me={me} setName={setMe} onLogin={handleLogin}/>
            </StyledMessage>
        </>
    )
}

export default SignIn